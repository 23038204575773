'use client'
import { ENV } from '@/lib/get-deployment-url'
import BBBLogo from '@/public/assets/bbb.svg'
import StripeLogo from '@/public/assets/stripe.svg'
import TrustPilotLogo from '@/public/assets/trustpilot.svg'
import SensayLogo from '@/public/logos/sensay-logo.svg'
import {
  DiscordLogo,
  FacebookLogo,
  type Icon,
  InstagramLogo,
  LinkedinLogo,
  MediumLogo,
  ShieldCheck,
  TelegramLogo,
  TiktokLogo,
  XLogo,
  YoutubeLogo,
} from '@phosphor-icons/react'
import Image from 'next/image'
import Link from 'next/link'
import MailchimpSubscribe, { type EmailFormFields } from 'react-mailchimp-subscribe'
import NewsletterForm from './NewsletterForm'

const MAILCHIMP_URL = process.env.NEXT_PUBLIC_MAILCHIMP_URL
const MAILCHIP_U = process.env.NEXT_PUBLIC_MAILCHIMP_U
const MAILCHIP_ID = process.env.NEXT_PUBLIC_MAILCHIMP_ID

const url = `${MAILCHIMP_URL}u=${MAILCHIP_U}&id=${MAILCHIP_ID}`

const nav = [
  {
    title: 'Solutions',
    items: [
      { title: 'Solutions for Teams', href: '/solutions-for-teams' },
      { title: 'Dementia', href: '/dementia' },
      { title: 'Education', href: '/education' },
      { title: 'Fan Engagement', href: '/fan-engagement' },
      { title: 'Custom Replicas', href: '/custom-replicas' },
      { title: 'Affiliate', href: '/affiliate' },
      { title: 'Community Replicas', href: '/community' },
    ],
  },
  {
    title: 'Support',
    items: [
      { title: '$SNSY', href: 'https://www.snsy.ai/' },
      { title: 'Pricing', href: '/pricing/year' },
      { title: 'Documentation', href: 'https://github.com/sensay-io' },
      { title: 'Feedback', href: 'https://sensay.typeform.com/to/dMevqrgs' },
    ],
  },
  {
    title: 'Company',
    items: [
      { title: 'Shop', href: 'https://shop.sensay.io' },
      { title: 'Apps', href: '/apps' },
      { title: 'About', href: '/about' },
      { title: 'Blog', href: 'https://blog.sensay.io' },
      { title: 'Investors', href: '/investors' },
      { title: 'Partners', href: '/partners' },
      { title: 'Careers', href: '/careers' },
    ],
  },
  {
    title: 'Legal',
    items: [
      { title: 'Privacy', href: '/privacy' },
      { title: 'Terms', href: '/terms' },
      { title: 'Take Down', href: '/take-down' },
      { title: 'CSR', href: '/corporate-social-responsibility' },
    ],
  },
]

const Footer = () => {
  return (
    <footer className="w-full bg-zinc-950">
      <div className="flex flex-col">
        <div className="mx-auto flex h-full w-full max-w-6xl flex-1 flex-col justify-between px-6 pb-6 pt-20 md:items-start md:pb-12 lg:flex-row">
          <div className="grid w-full grid-cols-2 gap-y-10 md:grid-cols-4 md:gap-y-0">
            {nav.map((item, index) => (
              <div key={index}>
                <h2 className="text-xl font-light text-white mb-3">{item.title}</h2>
                <div className="grid gap-y-3 text-zinc-400">
                  {item.items.map((subItems, index) => (
                    <p className="grid transition-colors duration-300 hover:text-primary" key={index}>
                      <a href={subItems.href}>{subItems.title}</a>
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="pt-20 lg:pt-0">
            <h3 className="text-xl font-light text-white">Subscribe to our Newsletter</h3>
            <p className="mt-2 text-zinc-400">The latest news, articles and resources, sent to your inbox.</p>
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <NewsletterForm
                  status={status}
                  message={message}
                  onValidated={(formData: EmailFormFields) => {
                    subscribe(formData)
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className="mx-auto w-full max-w-6xl md:px-6">
          <div className="flex flex-col items-center justify-between gap-9 border-t border-white/10 py-8 md:flex-row">
            <div className="flex flex-wrap items-center justify-center gap-6 px-6 md:justify-start md:px-0">
              <div className="flex items-center justify-center gap-1 text-white md:justify-start">
                <Link href="/" className="transition-colors duration-300 hover:text-primary">
                  <SensayLogo className="h-[32px] fill-current" />
                </Link>

                <span className="rounded-md bg-white/10 px-2 py-1 text-xs text-white ring-1 ring-inset ring-white/20">
                  βeta
                </span>

                {ENV !== 'production' && (
                  <span className="rounded-md bg-red-500 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-gray-500/10">
                    {ENV}
                  </span>
                )}
              </div>

              <Link
                href="https://www.producthunt.com/posts/sensay-replicas?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-sensay&#0045;replicas"
                target="_blank"
                className="opacity-40"
                rel="noreferrer"
              >
                <Image
                  src="/assets/producthunt.svg"
                  alt="Sensay - Dementia care with lifelike AI replicas | Product Hunt"
                  width={200}
                  height={40}
                />
              </Link>
            </div>

            <div className="flex w-full flex-shrink-0 flex-wrap items-center justify-center gap-6 px-12 text-zinc-200 md:w-auto md:px-0">
              <SocialMediaLink name="Facebook" href="https://www.facebook.com/asksensay" icon={FacebookLogo} />
              <SocialMediaLink name="Instagram" href="https://www.instagram.com/asksensay" icon={InstagramLogo} />
              <SocialMediaLink name="Twitter" href="https://www.twitter.com/asksensay" icon={XLogo} />
              <SocialMediaLink name="LinkedIn" href="https://www.linkedin.com/company/asksensay" icon={LinkedinLogo} />
              <SocialMediaLink name="Tiktok" href="https://www.tiktok.com/@asksensay" icon={TiktokLogo} />
              <SocialMediaLink name="Facebook" href="https://www.youtube.com/@asksensay" icon={YoutubeLogo} />
              <SocialMediaLink name="Discord" href="https://discord.com/invite/sensay" icon={DiscordLogo} />
              <SocialMediaLink name="Facebook" href="https://t.me/asksensay" icon={TelegramLogo} />
              <SocialMediaLink name="Medium" href="https://blog.sensay.io" icon={MediumLogo} />
            </div>
          </div>

          <div className="flex flex-col-reverse items-center gap-6 border-t border-white/10 px-6 py-8 text-zinc-400 md:items-start lg:items-center md:justify-between md:px-0 lg:flex-row">
            <div className="mt-6 md:mt-0 flex flex-col gap-1">
              <p className="text-center text-xs leading-5 md:text-left">&copy; 2024 Sensay. All rights reserved</p>
              <Link
                target="_blank"
                href={'https://etherscan.io/address/0x82a605D6D9114F4Ad6D5Ee461027477EeED31E34'}
                className="cursor-pointer text-center text-xs leading-5 duration-150 hover:text-zinc-50 md:text-left"
              >
                $SNSY Ethereum Network: 0x82a605D6D9114F4Ad6D5Ee461027477EeED31E34
              </Link>
              <Link
                target="_blank"
                href={'https://arbiscan.io/token/0x3124678d62d2aa1f615b54525310fbfda6dcf7ae'}
                className="cursor-pointer text-center text-xs leading-5 duration-150 hover:text-zinc-50 md:text-left"
              >
                $SNSY Arbitrum & Base: 0x3124678D62D2aa1f615B54525310fbfDa6DcF7AE
              </Link>
            </div>

            <div className="mx-auto flex max-w-52 flex-wrap items-center justify-center gap-6 opacity-60 md:mx-0 md:max-w-none md:gap-12">
              <div className="flex items-center">
                <ShieldCheck className="mr-1 h-6 w-6" weight="duotone" />
                Encrypted
              </div>
              <BBBLogo className="h-14 shrink-0" />
              <Link href="https://uk.trustpilot.com/review/sensay.io">
                <TrustPilotLogo className="h-6 shrink-0" />
              </Link>
              <StripeLogo className="h-8 shrink-0" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

const SocialMediaLink = ({ href, icon: Icon, name }: { href: string; icon: Icon; name: string }) => (
  <Link href={href} target="_blank" className="cursor-pointer transition-colors duration-300 hover:text-primary">
    <span className="sr-only">{name}</span>
    <Icon aria-hidden="true" className="h-[24px] w-[24px]" />
  </Link>
)
